<template>
  <div class="page">
    <div class="body">
      <div class="left">
        <p class="left-title">
          店内环境图：<span>(图片建议尺寸800*300，大小在500kb以内)</span>
        </p>
        <li class="img-list" v-if="params.picIdUrl">
          <el-image
            class="img-item"
            :src="params.picIdUrl"
            :preview-src-list="[params.picIdUrl]"
          >
          </el-image>
          <div class="img-edit">
            <el-switch
              v-model="params.activityStatus"
              active-color="#EF9807"
              inactive-color="#909399"
            >
            </el-switch>
            <i class="el-icon-edit" @click="onUpload(item)"></i>
          </div>
        </li>
        <div
          class="upload-btn"
          @click="onUpload('轮播图')"
          v-if="!params.picIdUrl"
        >
          <i class="el-icon-plus uploader-icon"></i>
        </div>
        <div class="actyvity-type" v-if="params.picIdUrl">
          <label>跳转</label>
          <el-select v-model="params.activityType" placeholder="请选择跳转">
            <el-option
              v-for="item in ObjToOpt(activityTypeObj)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div
          class="actyvity-type"
          v-if="params.picIdUrl && params.activityType == 3"
        >
          <label>公众号</label>
          <el-input
            v-model="params.linkUrl"
            placeholder="请输入公众号地址"
            clearable=""
          ></el-input>
        </div>
        <el-button
          style="margin-top: 20px"
          type="primary"
          @click="getActivityEdit"
          >保存</el-button
        >
      </div>
      <div class="right">
        <p>小程序展示样式</p>
        <img src="~@/assets/images/mini/activity.png" alt="" />
      </div>
    </div>

    <!-- 图片上传组件 -->
    <UpLoad @fileIds="getFileIds" ref="up" :fileType="1"></UpLoad>
  </div>
</template>

<script>
import UpLoad from "@/components/upload";
import { getActivityInfo, getActivityEdit } from "@/api/activity/activity";
import { activityTypeObj } from "@/db/objs";
import { ObjToOpt } from "@/utils/utils";
export default {
  components: { UpLoad },
  data() {
    return {
      activityTypeObj,
      ObjToOpt,
      params: {
        shopId: "", //门店ID
        picId: "", //图片id
        picIdUrl: "", //图片路径
        activityStatus: false, //是否启用
        activityType: "", //卡券类型
        linkUrl:""// 公众号地址
      },
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.params.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getActivityInfo(); // 【请求】图片详情
  },
  methods: {
    // 【请求】图片详情
    getActivityInfo() {
      let data = this.params;
      getActivityInfo(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.params = res.data;
          }
        }
      });
    },

    // 保存设置
    getActivityEdit() {
      let data = this.params;
      getActivityEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getActivityInfo();
        }
      });
    },

    // 【监听】文件上传
    onUpload() {
      this.$refs.up.onUpload();
    },

    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      this.params.picId = fileIds;
      this.getActivityEdit();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 0.15rem;
}
.body {
  display: flex;
  justify-content: space-between;
}
@import "~@/assets/styles/theme";

.upload-btn {
  border: 1px dashed $theme-color;
  border-radius: 6px;
  cursor: pointer;
  width: 4rem;
  height: 1.5rem;
}

.upload-btn:hover {
  border-color: $theme-color;
}

.uploader-icon {
  font-size: 28px;
  color: $theme-color;
  width: 4rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
}

.img-list {
  margin: 0.1rem 0;
  display: flex;
}

.img-edit {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 0.2rem;
  width: 1.5rem;

  > i {
    font-size: 0.2rem;
    cursor: pointer;
  }
}

.img-edit > i:hover {
  color: $theme-color;
}

.img-item {
  width: 4rem;
  height: 1.5rem;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
}

.actyvity-type {
  width: 4rem;
  margin-top: 20px;
  display: flex;
  box-sizing: border-box;

  > label {
    width: 1rem;
    display: inline-flex;
    align-items: center;
    // justify-content: center;
  }
}

.left-title {
  margin-bottom: 0.15rem;
  span {
    font-size: 0.14rem;
    color: #999999;
  }
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 0.15rem;
  }
}
</style>